.about-page {
  display: grid;
  grid-template-areas:
    " tg-nav "
    " about-title "
    " about-content "
    " about-code ";
  grid-template-rows: 50px 200px auto auto;
  grid-template-columns: 1fr;
  grid-gap: 0;
}

.about-title {
  margin: 10px 0 0 0;
  padding: 20px;
  grid-area: about-title;
  background: var(--iah-grey-med);
}
.about-content {
  grid-area: about-content;
  display: block;
  width: 100%;
  padding: 20px;
  margin: 0;

  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
}
.about-code {
  grid-area: about-code;
}

.contact-page {
  display: grid;
  grid-template-areas:
    " tg-nav "
    " contact-title "
    " contact-content "
    " contact-code ";
  grid-template-rows: 50px 200px auto auto;
  grid-template-columns: 1fr;
  grid-gap: 0;
}

.contact-title {
  grid-area: contact-title;
  margin: 10px 0 0 0;
  padding: 20px;
  background: var(--iah-grey-med);
}
.contact-content {
  grid-area: contact-content;
  display: block;
  width: 100%;
  padding: 20px;
  margin: 0;

  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
}
.contact-code {
  grid-area: contact-code;
}

.services-page {
  display: grid;
  grid-template-areas:
    " tg-nav "
    " services-title "
    " services-content "
    " services-code ";
  grid-template-rows: 50px 200px auto auto;
  grid-template-columns: 1fr;
  grid-gap: 0;
}

.services-title {
  grid-area: services-title;
  margin: 10px 0 0 0;
  padding: 20px;
  background: var(--iah-grey-med);
}
.services-content {
  grid-area: services-content;
  display: block;
  width: 100%;
  padding: 20px;
  margin: 0;

  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
}
.services-code {
  grid-area: services-code;
}

@media (min-width: 600px) {
  .about-page {
    grid-template-areas:
      " tg-bar-main tg-bar-main tg-bar-main     tg-bar-main "
      " .           tg-nav      about-title     . "
      " .           tg-nav      about-content   . "
      " .           tg-nav      about-code      . ";
    grid-template-rows: 40px 200px auto auto;
    grid-template-columns:
      minmax(20px, 1fr)
      200px
      minmax(200px, 1200px)
      minmax(20px, 1fr);
  }

  .contact-page {
    grid-template-areas:
      " tg-bar-main tg-bar-main tg-bar-main     tg-bar-main "
      " .           tg-nav      contact-title     . "
      " .           tg-nav      contact-content   . "
      " .           tg-nav      contact-code      . ";
    grid-template-rows: 40px 200px auto auto;
    grid-template-columns:
      minmax(20px, 1fr)
      200px
      minmax(200px, 1200px)
      minmax(20px, 1fr);
  }

  .services-page {
    grid-template-areas:
      " tg-bar-main tg-bar-main tg-bar-main     tg-bar-main "
      " .           tg-nav      services-title     . "
      " .           tg-nav      services-content   . "
      " .           tg-nav      services-code      . ";
    grid-template-rows: 40px 200px auto auto;
    grid-template-columns:
      minmax(20px, 1fr)
      200px
      minmax(200px, 1200px)
      minmax(20px, 1fr);
  }
}
